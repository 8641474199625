:root {
  --dark-red: #a20007;
  --black: #333333;
  --grey: #adadad;
  --white: #ffffff;
}

.Overlay {
  position: fixed;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  z-index: 1200;
  margin: 0 !important;
}

.MainDiv {
  border-radius: 5px;
  padding: 17px 20px;
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: auto;
  min-height: 80vh;
  max-height: 90vh;
  height: fit-content;
  width: 297px;
  -webkit-box-shadow: 0 3px 3px 2px #00000029;
  box-shadow: 0 3px 3px 2px #00000029;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  overflow-x: hidden;
}

.MainDiv .RightButton {
  position: relative;
  bottom: 0;
  right: 10%;
}

.MainDiv .SeparatorLine {
  display: none;
}

.RightButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  align-self: flex-end;
  margin-right: -40px;
  margin-top: -8px;
  margin-bottom: 10px;
}

.CloseButton {
  height: 16px;
  width: 16px;
  font-family: 'Montserrat-Bold';
  font-weight: bold;
  font-size: 17px;
  border: none;
  background-color: transparent;
  color: var(--grey);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 42%;
  cursor: pointer;
}

.CloseButton:hover {
  color: black;
  opacity: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  z-index: 200;
  cursor: pointer;
}

.ModalDiv div:nth-child(1) p br {
  display: none;
}

.ModalDiv div:not(div:nth-child(1)) img {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.ModalDiv div:not(div:nth-child(1)) p {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  justify-self: left;
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  text-align: left;
  padding: 0 16px 0 0;
}

.ModalDiv div:not(div:nth-child(1)) button {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.ModalDiv {
  height: 100%;
}

.InstructionImagesSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.InstructionImagesSection > img {
  margin: 15px auto 0;
  width: 39%;
}

.TextMedium {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  color: var(--black);
}

.AddButton {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  font-family: 'Montserrat-Bold';
  font-size: 19px;
  border: var(--primary-color);
  border-style: solid;
  border-width: 1px;
  background-color: var(--primary-color);
  color: white;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.AddButton:hover {
  color: black;
  background-color: var(--dark-red);
  border: var(--dark-red);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.ButtonsDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 40px 0 3px;
}

.GoBackButton {
  margin-right: 15px;
  cursor: pointer;
}

.ContinueButton {
  width: 100%;
  font-size: 16px !important;
  margin-top: 10px;
}

.FacephiModalHeader > h1 {
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: 800;
}

.FacephiModalHeader > p {
  margin-bottom: 10px;
  font-size: 0.8rem;
}

.Instructions > p {
  margin: 0 0 5px 0;
  text-align: center;
  color: var(--primary-color);
  font-weight: 600;
}

.FinalSpinner {
  object-fit: contain;
}

.SuccessMessage {
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.SuccessMessage > img {
  max-width: 80%;
  min-width: 50%;
  margin: 50px auto;
}

.ErrorDiv {
  display: flex;
  flex-direction: column;
}

.ErrorDiv > div {
  display: flex;
  width: 100%;
  position: absolute;
  bottom: 45px;
}

.ErrorDiv > h1 {
  margin-bottom: 20px;
}

.ErrorDiv > p {
  width: 50%;
  align-self: left;
}

.ErrorDiv > div > button:nth-child(1) {
  margin-right: 1rem;
}

.FinalLoader {
  width: 100%;
  object-fit: cover;
}

@media (min-width: 576px) {
  .MainDiv {
    border-radius: 6px;
    padding: 45px 45px 20px 52px;
    height: fit-content;
    width: calc(70% + 30px);
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 904px;
    margin: 21px 28px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .SephiModalHeight {
    height: 85vh;
  }

  .MainDiv .RightButton {
    bottom: 20px;
    left: -10px;
  }

  .MainDiv div:nth-child(3) {
    margin-left: auto;
    margin-right: auto;
  }

  .MainDiv .SeparatorDiv {
    margin-top: 48px;
  }

  .MainDiv .SeparatorLine {
    display: block !important;
    width: 773px;
  }

  .MainDiv .ButtonsDiv {
    margin: 15px 64px 0 0;
  }

  .ModalDiv {
    width: 80%;
    -webkit-column-gap: 56px;
    -moz-column-gap: 56px;
    column-gap: 56px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
  }

  .ModalDiv div:nth-child(1) p br {
    display: block;
  }

  .ModalDiv div:nth-child(1) {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }

  .ModalDiv div:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .ModalDiv div:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .ModalDiv div:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  .ModalDiv div:nth-child(5) {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
  }

  .ModalDiv div:not(div:nth-child(1)) img {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .ModalDiv div:not(div:nth-child(1)) p {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: center;
    text-align: center;
    padding: 0 5px;
  }

  .ModalDiv div:not(div:nth-child(1)) button {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .TextMedium {
    font-size: 14px;
  }

  .ButtonsDiv {
    margin: 15px 64px 0 0;
  }

  .FacephiModalHeader {
    text-align: center;
    margin-bottom: 10px;
  }

  .FacephiModalHeader > h1 {
    margin-bottom: 10px;
    font-size: 2rem;
    font-weight: 800;
  }

  .FacephiModalHeader > p {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .Instructions {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 150px;
  }

  .Instructions > p {
    font-size: 16px !important;
  }

  .ContinueButton {
    width: fit-content;
    float: right;
  }

  .SuccessMessage > img {
    height: 400px;
    width: 400px;
    margin: 100px auto 0;
  }

  .InstructionImagesSection {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

  .InstructionImagesSection > img {
    margin: 15px auto 0;
    width: 120px;
  }

  .FacePhiInstructions {
    height: 100%;
  }

  .FacePhiInstructions > div:nth-child(2) {
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .FacePhiInstructions > div:nth-child(2) > button {
    justify-content: flex-end;
  }

  .ContinueButton {
    width: fit-content;
    margin-left: auto;
  }
}

@media (min-width: 577px) and (max-width: 790px) {
  .MainDiv {
    width: 456px;
  }
}

@media (min-width: 1200px) {
  .MainDiv {
    bottom: 30px;
    z-index: 2;
    height: 80%;
  }
}

@media screen and (min-width: 1200px) and (max-height: 800px) {
  .MainDiv {
    z-index: 2;
  }
}
