.Overlay {
  position: fixed;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  z-index: 1200;
}

.Card {
  border-radius: 5px;
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
  padding: 20px 50px;
  width: fit-content;
  height: fit-content;
  align-items: center;
  -webkit-box-shadow: 0 3px 3px 2px #00000029;
  box-shadow: 0 3px 3px 2px #00000029;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ButtonRight {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  align-self: flex-end;
  margin-right: -40px;
  margin-top: -8px;
  margin-bottom: 10px;
}

.CloseButton {
  height: 16px;
  width: 16px;
  font-family: 'Montserrat-Bold';
  font-weight: bold;
  font-size: 17px;
  border: none;
  background-color: transparent;
  color: var(--grey);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 42%;
}

.CloseButton:hover {
  color: black;
  opacity: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  z-index: 200;
}

.ModalText {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.ModalInput {
  height: 38px;
  width: 250px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 4px !important;
  box-sizing: border-box;
}

.ModalError {
  visibility: visible;
  margin-top: 10px !important;
  margin-bottom: -20px !important;
  padding: 0;
  font-size: 11px;
  color: #710005;
}

.ModalErrorHide {
  visibility: hidden !important;
  margin-top: 10px !important;
  margin-bottom: -20px !important;
  padding: 0;
  font-size: 11px;
}

.ModalLineSeparator {
  top: 7px;
  position: relative;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-top: 25px;
}

.CardModalButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 14px 0 3px;
}

@media screen and (max-width: 320px) {
  .Card {
    z-index: 999999999;
    width: 200px !important;
  }
}

@media screen and (min-width: 321px) and (max-width: 360px) {
  .Card {
    z-index: 999999999;
  }
}

@media (min-width: 576px) {
  .Card {
    border-radius: 6px;
    padding: 45px 45px 20px 52px;
    right: 30px;
    position: relative;
    bottom: 30px;
  }
}

@media screen and (min-width: 361px) and (max-width: 580px) {
  .Card {
    z-index: 999999999;
  }
}

@media (min-width: 577px) and (max-width: 790px) {
  .Card {
    width: 456px;
  }
}

@media screen and (min-width: 580px) and (max-width: 768px) {
  .Card {
    z-index: 999999999;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .Card {
    z-index: 999999999;
  }
}

@media (min-width: 1200px) {
  .Card {
    bottom: 30px;
    z-index: 2;
  }
}

@media screen and (min-width: 1200px) and (max-height: 800px) {
  .Card {
    z-index: 2;
  }
}
