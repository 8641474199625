.GreenBox {
  color: #009122;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #009122;
  background-color: hsla(134, 100%, 28%, 0.149);
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
}

.GreenText {
  color: #009122;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.FirstSubtitleTextMargin {
  margin: 0 auto;
}

.CheckCircle {
  height: 20px;
  width: 20px;
  margin-right: 20px;
}

.InformationDiv {
  border: 1px solid #c62a2126;
  border-radius: 5px;
  padding: 15px 8px;
}

.InputWrapper {
  display: flex;
  flex-direction: column;
}

.InputTitle {
  font-size: 12px;
  color: #333333;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

.UserAppImg {
  height: 80px;
  order: -1;
  margin-bottom: 10px;
}

.DisclaimerText {
  font-style: italic;
  font-size: 12px;
}

.InputMargin {
  margin: 18px 0 3px;
}

.ButtonsDiv {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.ContinueButton {
  margin: 15px auto 0;
}

@media (min-width: 900px) {
  .InformationDiv {
    padding: 35px 15px 15px 40px;
  }

  .UserAppImg {
    margin-right: 30px;
    order: 2;
    height: 160px;
  }

  .InputWrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .InputTitle {
    font-size: 14px;
    text-align: start;
    width: 100%;
  }

  .InputDiv {
    width: 62%;
  }

  .InputMargin {
    margin: 15px 0;
  }

  .ButtonsDiv {
    margin-top: 30px;
    flex-direction: row;
  }

  .ContinueButton {
    margin: 0;
  }
}
