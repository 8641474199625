:root {
  --dark-red: #a20007;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.Button {
  height: 38px;
  border-radius: 5px;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  color: var(--primary-color);
  border: var(--primary-color);
  border-style: solid;
  border-width: 1px;
  padding: 0 16px;
  background-color: white;
}

.Button:hover {
  color: white;
  background-color: var(--dark-red);
  border: 1px solid var(--dark-red);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
