:root {
  --black: #333333;
  --success-color: #009122;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
  overflow: visible;
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.Alert {
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 11px 10px;
  gap: 10px;
  margin: 24.5px 0;
  border: 1px solid var(--success-color);
  background-color: #00912226;
  color: var(--success-color);
}

.Alert p {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  text-align: center;
}

.Validation {
  border-radius: 5px;
  border: 1px solid #c62a2126;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 12px 10px;
}

.Validation img {
  width: 85px;
}

.Validation p,
.Validation a {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  text-align: center;
}

.Validation p {
  color: var(--black);
  padding: 11px 0;
}

.Validation a {
  color: var(--primary-color);
}

.ValidationButton {
  color: var(--primary-color);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  text-decoration: underline;
  font-family: 'Montserrat-Medium';
  font-size: 16px;
  text-align: left;
  padding-left: 0px !important;
}

.LineSeparator {
  top: 7px;
  position: relative;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  display: none;
}

@media (min-width: 576px) {
  .Alert {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    gap: 20px;
  }

  .Alert p {
    font-size: 14px;
  }

  .Validation {
    display: grid;
    grid-template-columns: 60% 30%;
    grid-column-gap: 6%;
    height: 136px;
  }

  .Validation img:nth-child(1) {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    width: 125px;
  }

  .Validation p:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-size: 14px;
    text-align: left;
    margin-left: 31px;
  }

  .Validation a:nth-child(3) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    font-size: 16px;
    text-align: left;
    margin-left: 31px;
    bottom: 15px;
    position: relative;
  }

  .CardSeparator .LineSeparator {
    display: block;
  }

  .ValidationButton {
    margin-left: 31px;
  }
}

@media (min-width: 951px) and (max-width: 1080px) {
  .LineSeparator {
    display: none;
  }
}

@media (min-width: 577px) and (max-width: 1199px) {
  .LineSeparator {
    display: none;
  }
}
