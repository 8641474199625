.MainDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 19px;
}

.Switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 19px;
}

.Switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Slider:before {
  position: absolute;
  content: '';
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.Input:checked + .Slider {
  background-color: var(--primary-color);
}

.Input:focus + .Slider {
  box-shadow: 0 0 1px var(--primary-color);
}

.Input:checked + .Slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.Slider {
  border-radius: 34px;
}

.Slider:before {
  border-radius: 50%;
}

.Label {
  font-size: 14px;
  margin-left: 11px;
}
