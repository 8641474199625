:root {
  --light-grey: #eeeeee;
}

.Footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--light-grey);
  width: 100%;
}

.FooterText {
  background-color: var(--light-grey);
  padding: 6px 15px;
}

.FooterText > p {
  font-family: 'Montserrat-Regular';
  font-size: 9px;
  text-align: center;
  line-height: 1.1;
}

@media (min-width: 577px) and (max-width: 790px) {
  .Footer {
    width: 790px;
  }
}

@media (min-width: 950px) {
  .FixedFooter {
    position: absolute;
    bottom: -2px;
    left: 0;
  }

  .BotomPageFooter {
    margin-top: auto;
  }
}
