.Navbar {
  height: 56px;
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  -webkit-box-shadow: 0 0 3px 3px #00000029;
  box-shadow: 0 0 3px 3px #00000029;
}

.Logo {
  display: block;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.Logo img {
  height: 36px;
}

@media (min-width: 576px) {
  .Navbar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-left: 10vw;
  }
}

@media (max-width: 500px) {
  .Navbar {
    position: fixed;
    width: 100%;
    z-index: 120;
    background-color: #ffffff;
  }
}

@media (min-width: 577px) and (max-width: 790px) {
  .Navbar {
    padding-left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
    width: 790px;
  }
}
