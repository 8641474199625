:root {
  --dark-red: #a20007;
  --black: #333333;
  --grey: #adadad;
  --white: #fff;
}

.Overlay {
  position: fixed;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  min-height: 700px;
  height: 100%;
  opacity: 1;
  top: 0;
  left: 0;
  z-index: 1200;
  margin: 0 !important;
  display: flex;
  justify-content: center;
  overflow: auto;
}

.MainDiv {
  border-radius: 5px;
  padding: 17px 20px;
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: auto;
  width: 80%;
  min-height: 600px;
  margin: 20px 0;
  height: 85vh;
  -webkit-box-shadow: 0 3px 3px 2px #00000029;
  box-shadow: 0 3px 3px 2px #00000029;
}

.MainDiv .RightButton {
  position: relative;
  bottom: 5px;
  right: 25px;
}

.MainDiv .SeparatorLine {
  display: none;
}

.RightButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  align-self: flex-end;
  margin-right: -40px;
  margin-top: -8px;
  margin-bottom: 10px;
}

.CloseButton {
  height: 16px;
  width: 16px;
  font-family: 'Montserrat-Bold';
  font-weight: bold;
  font-size: 17px;
  border: none;
  background-color: transparent;
  color: var(--grey);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  opacity: 42%;
  cursor: pointer;
}

.CloseButton:hover {
  color: black;
  opacity: 1;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  z-index: 200;
  cursor: pointer;
}

.ModalDiv {
  height: calc(100% - 20px);
}

.ModalDiv > div {
  height: 100%;
}

.Form {
  display: flex;
  flex-direction: column;
  height: calc(100% - 40px);
  justify-content: space-between;
}

.ModalDiv div:nth-child(1) p br {
  display: none;
}

.ModalDiv div:not(div:nth-child(1)) {
  grid-template-columns: 50% 50%;
  grid-template-rows: 80% 20%;
  justify-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}

.ModalDiv div:not(div:nth-child(1)) img {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.ModalDiv div:not(div:nth-child(1)) p {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  justify-self: left;
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  text-align: left;
  padding: 0 16px 0 5px;
  color: var(--grey);
}

.ModalDiv div:not(div:nth-child(1)) button {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.ModalDiv > div > div > h1 {
  font-size: 1.2rem;
}

.ModalDiv p {
  font-size: 0.9rem;
}

.TextMedium {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  color: var(--black);
}

.AddButton {
  height: 26px;
  width: 26px;
  border-radius: 50%;
  font-family: 'Montserrat-Bold';
  font-size: 19px;
  border: var(--primary-color);
  border-style: solid;
  border-width: 1px;
  background-color: var(--primary-color);
  color: var(--white);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.AddButton:hover {
  color: black;
  background-color: var(--dark-red);
  border: var(--dark-red);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.ButtonsDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 40px 0 3px;
}

.GoBackButton {
  margin-right: 15px;
  cursor: pointer;
}

.HideInput {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
}

.ShowInput {
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
}

.ShowInput > input {
  border: 1px solid var(--grey);
}

.PillsMarginBottom {
  margin-bottom: 215px;
}

.PillsDiv {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.FacePhiSelphIdHeader {
  margin-bottom: 12px;
}

.FacePhiSelphIdHeader > h1 {
  font-size: 1.2rem;
}

.FacePhiSelphIdHeader > p {
  font-size: 0.9rem;
}

.Facephi {
  width: 100%;
  height: 100%;
  background-color: #000000;
  min-height: 550px;
  margin-top: 10px;
}

.Document {
  width: 525px;
  height: 350px;
  margin: 0 auto;
}

.ButtonPill {
  border-radius: 5px;
  margin: 10px 10px 10px 0;
}

.DocumentImage {
  width: 100%;
}

.SerialCodeInput {
  justify-content: flex-end;
  width: 100%;
}

@media (min-width: 576px) {
  .Overlay {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MainDiv {
    border-radius: 6px;
    padding: 45px 45px 20px 52px;
    height: 80vh;
    width: calc(70% + 30px);
    width: 904px;
    margin: 21px 28px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: center;
  }

  .FacePhiSelphIdModal {
    height: 725px;
  }

  .MainDiv .RightButton {
    bottom: 20px;
    left: -10px;
  }

  .PillsDiv {
    flex-direction: row;
  }

  .MainDiv div:nth-child(3) {
    margin-left: auto;
    margin-right: auto;
  }

  .MainDiv .SeparatorDiv {
    margin-top: 48px;
  }

  .MainDiv .SeparatorLine {
    display: block !important;
    width: 773px;
  }

  .MainDiv .ButtonsDiv {
    margin: 15px 64px 0 0;
  }

  .ModalDiv {
    grid-template-columns: repeat(4, 146px);
    grid-template-rows: 82px 220px;
    -webkit-column-gap: 56px;
    -moz-column-gap: 56px;
    column-gap: 56px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
    width: 70%;
  }

  .ModalDiv div:nth-child(1) p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .ModalDiv div:nth-child(1) p br {
    display: block;
  }

  .ModalDiv div:nth-child(1) {
    grid-column: 1 / 5;
    grid-row: 1 / 2;
  }

  .ModalDiv div:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .ModalDiv div:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .ModalDiv div:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  .ModalDiv div:nth-child(5) {
    grid-column: 4 / 5;
    grid-row: 2 / 3;
  }

  .ModalDiv div:not(div:nth-child(1)) img {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .ModalDiv div:not(div:nth-child(1)) p {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-self: center;
    text-align: center;
    padding: 0 5px;
  }

  .ModalDiv div:not(div:nth-child(1)) button {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  .TextMedium {
    font-size: 14px;
  }

  .ButtonsDiv {
    margin: 15px 64px 0 0;
  }

  .FacePhiSelphIdHeader {
    text-align: center;
  }

  .DocumentImage {
    width: 60%;
  }

  .Form:nth-child(2) {
    align-items: flex-end;
  }

  .SerialCodeInput {
    justify-content: right;
    float: right;
    width: fit-content;
  }

  .HideInput {
    margin-top: 24px;
  }
}

@media (min-width: 577px) and (max-width: 790px) {
  .MainDiv {
    width: 456px;
  }
}

@media (min-width: 1200px) {
  .MainDiv {
    bottom: 30px;
    z-index: 2;
  }
}

@media screen and (min-width: 1200px) and (max-height: 800px) {
  .MainDiv {
    z-index: 2;
  }
}
