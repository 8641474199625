.Input {
  border-radius: 5px;
  height: 38px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: var(--black);
  padding-left: 12px;
}

.InputMargin {
  margin: 10px 0 15px 0;
  width: -webkit-fill-available;
}

.Input::-webkit-input-placeholder {
  color: var(--grey);
  opacity: 1;
}

.Input::-moz-placeholder {
  color: var(--grey);
  opacity: 1;
}

.Input:-ms-input-placeholder {
  color: var(--grey);
  opacity: 1;
}

.Input::-ms-input-placeholder {
  color: var(--grey);
  opacity: 1;
}

.Input::placeholder {
  color: var(--grey);
  opacity: 1;
}
