.MainDiv {
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
  background-color: var(--primary-color);
}

.MainDiv h2 {
  color: #ffffff;
  font-family: 'Montserrat-Bold';
  font-size: 22px;
}

.MainDiv p {
  color: #ffffff;
  font-family: 'Montserrat-Medium';
  font-size: 16px;
}

.Button {
  height: 38px;
  border-radius: 5px;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  color: var(--primary-color);
  border: var(--primary-color);
  border-style: solid;
  border-width: 1px;
  padding: 0 16px;
}

.CDLCWhiteLogo {
  fill: #ffffff !important;
  width: 300px;
  height: 155px;
  margin-right: 15px;
  margin-top: 80px;
}

@media (min-width: 576px) {
  .MainDiv h2 br {
    display: none;
  }

  .MainDiv p br:nth-child(1) {
    display: none;
  }

  .MainDiv p br:nth-child(2) {
    display: block;
  }

  .WithdrawLink {
    font-family: 'Montserrat-Medium';
    font-size: 16px;
    color: #ffffff !important;
  }
}
