.TextMedium {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
}

.Input {
  border-radius: 5px;
  height: 38px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: var(--black);
  padding-left: 12px;
}

.InputMargin {
  margin: 10px 0 15px;
  width: -webkit-fill-available;
}

.Input::-moz-placeholder,
.Input::-webkit-input-placeholder,
.Input:-ms-input-placeholder,
.Input::-ms-input-placeholder,
.Input::placeholder {
  color: var(--grey);
  opacity: 1;
}

@media (min-width: 576px) {
  .TextMedium {
    font-size: 16px;
  }
}
