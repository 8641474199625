:root {
  --black: #333333;
}

.BoxInput {
  margin-top: 15px;
}

.BoxInput > p {
  margin-bottom: 9.5px;
}

.BoxInput div > p {
  margin-top: 5px;
}

.TextMedium {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  color: var(--black);
}

.Account {
  display: grid;
  grid-template-columns: 59% 39%;
  grid-template-rows: 40% 30% 30%;
  -webkit-column-gap: 2%;
  -moz-column-gap: 2%;
  column-gap: 2%;
  -webkit-box-pack: right;
  -ms-flex-pack: right;
  justify-content: right;
  height: 59px;
  border: 1px solid #e0e0e0;
  padding: 12px 15px 12px 12px;
  border-radius: 5px;
  margin: 13px 0px;
  cursor: pointer;
}

.Account p {
  font-family: 'Montserrat-Medium';
  font-size: 12px;
  color: var(--black);
}

.Account p:nth-child(1) {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  color: var(--primary-color);
  -o-text-overflow: clip;
  text-overflow: clip;
}

.Account p:nth-child(2) {
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.Account p:nth-child(3) {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  align-self: end;
}

.Account p:nth-child(4) {
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  color: var(--primary-color);
  justify-self: right;
  text-align: right;
}

.Account p:nth-child(1),
.Account p:nth-child(2),
.Account p:nth-child(3) {
  justify-self: left;
}

.ButtonDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 40px 0 3px;
}

.GoBackButton {
  margin-right: 15px;
  cursor: pointer;
}

@media (min-width: 576px) {
  .TextMedium {
    font-size: 14px;
  }

  .ButtonDiv {
    margin: 20px 0 0;
  }
}
