.Label {
  font-size: 14px;
  margin-bottom: 3px;
  font-family: 'Montserrat-Medium';
}

.MainDiv {
  position: relative;
  grid-template-rows: 50% 50%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  grid-row-gap: 6%;
  margin-bottom: 20px;
}

.MainDiv > p {
  margin-bottom: 9.5px;
}

.MainDiv div > p {
  margin-top: 5px;
}

.SelectDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 42px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin: 15px 0 -9px 0;
}

.SelectDiv input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.SelectDiv input::-ms-expand {
  display: none;
}

.SelectDiv .content-select {
  max-width: 250px;
  position: relative;
}

.SelectDiv input {
  display: inline-block;
  width: -webkit-fill-available;
  cursor: pointer;
  padding-left: 10px;
  height: 38px;
  outline: 0;
  background: #ffffff;
  font-size: 14px;
  color: #333333;
  font-family: 'Montserrat-Medium';
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.Input:focus-visible {
  outline: none;
}

.Input:focus-visible::placeholder {
  color: transparent;
}

.SelectOptions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  overflow-y: scroll;
  list-style: none;
  border: 1px solid #000010;
  background: #00000029 0% 0% no-repeat padding-box;
  box-shadow: 0 3px 6px #0000;
  opacity: 1;
  z-index: 2;
  padding-left: 0;
  width: 100%;
  margin: 0;
  max-height: 350px;
}

.HideDropdown {
  display: none;
}

.Option {
  color: #333333;
  height: 1.3rem;
  box-shadow: 0 3px 6px #00000029;
  background-color: #ffffff;
  line-height: 1rem;
  padding-left: 0.8rem;
  text-align: start;
  font-size: 0.9rem;
  font-weight: 400;
  font-family: 'Montserrat-Medium';
  cursor: pointer;
}

.Option:hover {
  background-color: #0080ff;
  color: white;
}

@media (min-width: 576px) {
  .MainDiv {
    margin-bottom: 0;
  }

  .Label {
    font-size: 16px;
  }
}

@media (min-width: 577px) and (max-width: 790px) {
  .Label {
    font-size: 13px;
  }
}

@media (min-width: 791px) and (max-width: 1199px) {
  .Label {
    font-size: 1.4vw;
  }
}
