.MainDiv {
  height: 100vh;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#c62a21), to(var(--dark-red)));
  background-image: -o-linear-gradient(top, #c62a21, var(--dark-red));
  background-image: linear-gradient(to bottom, #c62a21, var(--dark-red));
}

.MainDiv h2 {
  color: #ffffff;
  font-family: 'Montserrat-Bold';
  font-size: 22px;
}

.MainDiv p {
  color: #ffffff;
  font-family: 'Montserrat-Medium';
  font-size: 16px;
}

.Button {
  height: 38px;
  border-radius: 5px;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  border: var(--primary-color);
  color: var(--primary-color) !important;
  border-style: solid;
  border-width: 1px;
  padding: 0 16px;
  text-decoration: none;
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.CDLCWhiteLogo {
  fill: #ffffff !important;
  margin-top: 80px;
  width: 170px;
}

.Text {
  width: 400px;
}

@media (min-width: 1500px) {
  .Text {
    width: 600px;
  }
}

@media (max-width: 576px) {
  .Text,
  .Title {
    width: 250px;
  }
}
