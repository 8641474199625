:root {
  --dark-red: #a20007;
  --black: #333333;
  --grey: #adadad;
  --light-grey: #eeeeee;
  --primary-color: #ec1f39;
  --white: #ffffff;
  --new-secondary-red: #910200;
}

.MainDiv {
  background-color: #f7f8f9;
  padding-bottom: 16px;
  padding-top: 20%;
  display: grid;
  row-gap: 14px;
  grid-template-columns: 39px 87%;
  grid-template-rows: 24px 52px auto 52px 52px !important;
  width: 100%;
  height: auto;
}

.LatTop {
  display: none;
}

.Circle {
  background-color: var(--primary-color);
  border-radius: 50%;
  height: 8px;
  width: 8px;
  position: relative;
  left: 23px;
  top: 11px;
}

.Line {
  border-right: 2px solid;
  position: relative;
  right: 11px;
}

.RedLine {
  border-color: var(--primary-color);
}

.LineOne {
  height: 108%;
  top: 11px;
}

.Title {
  font-family: 'Montserrat-Medium';
  font-size: 20px;
  text-align: left;
  padding-left: 10px;
  font-weight: 100;
  color: var(--black);
}

.Icon img {
  width: 144%;
  z-index: 100;
  position: relative;
}

.Mobile {
  display: block;
}

.CardSeparator .LineSeparator,
.Desktop,
.LateralSim {
  display: none;
}

.LineTwo {
  height: 99%;
  top: -12px;
  height: 53% !important;
}

.SideText {
  font-family: 'Montserrat-Medium';
  font-size: 20px;
  position: relative;
  left: 50%;
  text-align: left;
}

.TextMedium {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
}

.RedText {
  color: var(--new-secondary-red) !important;
}

.Card {
  border-radius: 5px;
  padding: 17px 20px;
}

.CardCheck {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 52px !important;
  padding: 0 20px !important;
  background-color: #d7030c26;
}

.CardCheck p {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  text-align: left;
  color: var(--primary-color);
}

.CardCheck span {
  position: relative;
  left: 4px;
}

.CardTitle {
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  text-align: left;
}

.Rotar {
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  transform: rotate(135deg);
}

.Arrow,
.Check {
  display: inline-block;
  border-top: 1px solid #da0016;
  border-right: 1px solid #da0016;
  width: 13px;
  height: 5px;
}

.Arrow {
  width: 8px;
  height: 8px;
}

.GreyLine {
  border-color: var(--light-grey);
}

.LineThree {
  height: 95% !important;
  top: -13px;
}

.LateralSob {
  display: none;
}

.TextRed {
  color: var(--primary-color) !important;
}

.TextLateralActive {
  font-family: 'Montserrat-SemiBold';
  font-size: 20px;
}

.CardShadow {
  -webkit-box-shadow: 0 3px 3px 2px #00000029;
  box-shadow: 0 3px 3px 2px #00000029;
}

.CardActive {
  background-color: var(--white);
}

.CardSlideTwo {
  height: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.CardHeader {
  height: 49px;
}

.CardHeader div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.LineSeparator {
  top: 7px;
  position: relative;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.DataSlideTwo {
  margin-top: 0;
}

.Input {
  border-radius: 5px;
  height: 38px;
  background-color: var(--white);
  border: 1px solid #e0e0e0;
  font-size: 12px;
  font-family: 'Montserrat-Medium';
  color: var(--black);
  padding-left: 12px;
}

.InputMargin {
  margin: 10px 0 15px;
  width: -webkit-fill-available;
}

.Input::-moz-placeholder,
.Input::-webkit-input-placeholder,
.Input:-ms-input-placeholder,
.Input::-ms-input-placeholder,
.Input::placeholder {
  color: var(--grey);
  opacity: 1;
}

.BoxSelect {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: -9px;
}

.BoxSelect select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.BoxSelect select::-ms-expand {
  display: none;
}

.BoxSelect .content-select {
  max-width: 250px;
  position: relative;
}

.BoxSelect select {
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 7px 10px;
  height: 42px;
  outline: 0;
  border: 0;
  background: var(--white);
  color: var(--light-grey);
  font-size: 14px;
  color: #333333;
  font-family: 'Montserrat-Medium';
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  margin-bottom: 20px;
}

.BoxSelect i {
  position: relative;
  right: 20px;
  bottom: 47px;
  width: 8px;
  height: 8px;
  display: block;
  border-left: 1px solid #2d2d2d;
  border-bottom: 1px solid #2d2d2d;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.BoxSelect :hover i {
  margin-top: 3px;
}

.SelectClearing {
  padding-top: 15px;
}

.AlignCheckboxItems {
  display: flex;
}

.Checkbox {
  width: 65%;
  border-radius: 3px;
  margin: 0 !important;
  justify-content: start;
  position: relative;
  border: 0 solid #ebebeb;
  flex-grow: 3.4;
  max-width: 310px;
}

.Checkbox label {
  background: transparent;
  display: block;
  width: 250px;
  cursor: pointer;
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 1;
}

.Checkbox label p {
  margin-left: 7px;
  margin-top: -1.5px;
  width: 100%;
  font-size: 14px;
}

.Tooltip {
  background-color: #000000;
  color: var(--white);
  width: 500px;
  text-align: center;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;
  z-index: 2;
}

.TooltipCross {
  display: flex;
  justify-content: flex-end;
  padding-right: 5px;
  padding-bottom: 5px;
  font-size: 15px;
}

.Tooltip::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000000 transparent transparent transparent;
}

.TooltipIcon {
  cursor: pointer;
}

.EmptyDiv {
  flex-grow: 2.9;
}

.CardText p {
  font-family: 'Montserrat-Medium';
  font-size: 9px;
  color: var(--grey);
  text-align: left;
}

.LineSeparator {
  top: 7px;
  position: relative;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.CardButtons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 40px 0 3px;
}

.Button {
  height: 38px;
  border-radius: 5px;
  font-family: 'Montserrat-Regular';
  font-size: 14px;
  color: var(--primary-color);
  border: var(--primary-color);
  border-style: solid;
  border-width: 1px;
  padding: 0 16px;
}

.ButtonOutlinePrimary {
  color: var(--primary-color);
  background-color: var(--white);
}

.ButtonOutlinePrimary:hover {
  color: var(--white);
  background-color: var(--dark-red);
  border: 1px solid var(--dark-red);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.ButtonGoBack {
  margin-right: 15px;
  cursor: pointer;
}

.ButtonPrimary {
  color: var(--white);
  background-color: var(--primary-color);
  cursor: pointer;
}

.ButtonPrimary:hover {
  color: var(--white);
  background-color: var(--dark-red);
  border: 1px solid var(--dark-red);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.AlignLottieBtn {
  display: flex;
  justify-content: flex-start;
}

.GreyText {
  color: var(--grey) !important;
}

.CardDisabled {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 52px !important;
  background-color: var(--white);
  padding: 0 20px !important;
}

.CardDisabled p {
  font-family: 'Montserrat-Medium';
  font-size: 14px;
  color: var(--grey);
  text-align: left;
}

.WithdrawalForm {
  height: max-content;
}

.CardTitle {
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  text-align: left;
}

.SelectDiv {
  margin: 0 0 15px;
  grid-template-rows: 100% !important;
}

.LatTop,
.LateralSim,
.LateralSob,
.SideWithdrawal,
.LateralEst,
.LatBot,
.IFoot,
.Foot {
  display: none;
}

@media screen and (max-width: 414px) {
  .Checkbox label {
    align-items: center !important;
    position: unset !important;
  }

  .Checkbox {
    position: unset !important;
    display: flex;
    align-items: center;
  }

  .Checkbox label p {
    font-size: 11px !important;
    width: 96% !important;
  }

  .Checkbox a {
    font-size: 12px;
  }

  .Tooltip {
    width: 200px !important;
    margin-right: 50px !important;
  }

  .Tooltip::after {
    margin-left: 30px !important;
  }

  .EmptyDiv {
    flex-grow: 0 !important;
  }
}

@media (max-width: 320px) {
  .Title {
    font-size: 16px !important;
    margin-top: 5px !important;
  }

  .Tooltip::after {
    margin-left: 50px !important;
  }
}

@media (min-width: 321px) and (max-width: 375px) {
  .Title {
    font-size: 18px !important;
    margin-top: 5px !important;
  }
}

@media (min-width: 577px) and (max-width: 1024px) {
  .SideText {
    font-size: 2vw;
    left: 10%;
  }
}

@media (min-width: 575px) and (max-height: 1023px) {
  .Checkbox label p {
    margin-left: 25px;
    margin-top: -1.5px;
    width: 116%;
    font-size: 14px;
  }
}

@media (min-width: 415px) and (max-width: 575px) {
  .TycLabel {
    margin-left: 15px;
  }
}

@media (min-width: 576px) {
  .MainDiv {
    background-color: #f7f8f9;
    padding: 0;
    row-gap: 0;
    height: calc(100vh - 58px);
    grid-template-columns: 32.72% 60px auto;
    grid-template-rows: 127px repeat(4, 109px) auto !important;
    z-index: 2;
  }

  .LatTop,
  .LateralSim,
  .LateralSob,
  .SideWithdrawal,
  .LateralEst,
  .LatBot,
  .IFoot,
  .Foot {
    display: block;
  }

  .SideWithdrawal,
  .LateralEst {
    background-color: var(--white);
    display: flex;
    align-items: center;
    min-height: calc(100% + 1px);
    min-width: calc(100% + 1px);
  }

  .IEst {
    background-color: var(--white);
    min-height: calc(100% + 1px);
  }

  .LatTop {
    display: block;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% + 1px);
    min-width: calc(100% + 1px);
  }

  .ILine {
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: calc(100% + 1px);
  }

  .Circle {
    left: 0;
    height: 12px;
    width: 12px;
    top: 60px;
  }

  .Line {
    border-width: 4px;
    z-index: 200;
  }

  .LineOne {
    right: 8px;
    height: 88px;
    top: 70px;
  }

  .TitleDiv {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .Title {
    font-size: 32px;
    padding-left: 21px;
  }

  .ISim {
    background-color: var(--white);
    min-height: calc(100% + 1px);
  }

  .Icon img {
    width: 90px;
    right: 16px;
    top: 14px;
    z-index: 300;
  }

  .Mobile {
    display: none;
  }

  .Desktop {
    display: block;
  }

  .LineThree,
  .LineTwo {
    right: 30px;
  }

  .LineTwo {
    height: 63% !important;
  }

  .LineThree {
    top: -9px;
  }

  .LateralSim {
    display: block;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% + 1px);
    min-width: calc(100% + 1px);
  }

  .Card {
    border-radius: 6px;
    padding: 45px 45px 20px 52px;
  }

  .CardCheck {
    display: none;
  }

  .CardTitle {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    text-align: left;
  }

  .ISob {
    background-color: var(--white);
    min-height: calc(100% + 1px);
  }

  .LateralSob {
    display: block;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% + 1px);
    min-width: calc(100% + 1px);
  }

  .CardActive {
    height: 478px;
    width: calc(70% + 30px);
    right: 30px;
    position: relative;
    bottom: 30px;
  }

  .CardSlideTwo {
    height: 438px !important;
    bottom: 136px !important;
  }

  .CardHeader {
    display: none;
  }

  .datos--slide2-2 {
    display: flex;
    align-items: center;
  }

  .Data {
    display: grid;
  }

  .DataSlideTwo {
    grid-template-columns: 47% 47%;
    grid-template-rows: 50% 50%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-column-gap: 6%;
    margin-bottom: 15px;
  }

  .datos--slide2-2 {
    display: flex;
    align-items: center;
  }

  .DataSlideTwo p:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .DataSlideTwo p:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  .DataSlideTwo p:nth-child(3) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  .DataSlideTwo p:nth-child(4) {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  .TextMedium {
    font-size: 16px;
  }

  .TycLabel {
    display: flex;
  }

  .AlignCheckboxItems {
    padding-bottom: 3.75%;
  }

  .CardText {
    height: 13px;
    margin: 0;
  }

  .CardText p {
    font-size: 10px;
  }

  .CardText hr {
    top: 1px;
  }

  .CardSeparator .LineSeparator {
    display: block;
  }

  .CardButtons {
    margin: 20px 0 0 0;
  }

  .IForm {
    background-color: var(--white);
    min-height: calc(100% + 1px);
  }

  .LateralShape {
    display: block;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% + 1px);
    min-width: calc(100% + 1px);
  }

  .CardDisabled {
    display: none;
  }

  .lateral-est {
    display: block;
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% + 1px);
    min-width: calc(100% + 1px);
  }

  .CardTitle {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    text-align: left;
  }

  .LatBot {
    background-color: var(--white);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    min-width: calc(100% + 1px);
    align-items: center;
    min-height: calc(100% + 1px);
  }

  .IFoot {
    background-color: var(--white);
    min-height: calc(100% + 1px);
  }

  .SelectDiv {
    margin: 15px 0;
  }
}

@media (min-width: 577px) and (max-width: 950px) {
  .MainDiv {
    grid-template-columns: 21.72% 60px auto;
  }

  .SideText {
    font-size: 2vw;
    left: 10%;
  }
}

@media (min-width: 951px) and (max-width: 1080px) {
  .SideText {
    left: 30%;
  }
}

@media (min-width: 1200px) {
  .MainDiv {
    grid-template-columns: 30% 60px 800px;
  }

  .CardActive {
    bottom: 30px;
    z-index: 2;
  }
}

@media screen and (min-width: 1200px) and (max-height: 800px) {
  .MainDiv {
    grid-template-rows: 90px repeat(4, 109px) auto;
  }

  .LineOne {
    height: 103px;
    top: 55px;
  }

  .Title {
    font-size: 30px;
  }

  .CardActive {
    z-index: 2;
  }
}

@media (min-width: 415px) and (max-width: 575px) {
  .TycLabel {
    margin-left: 15px;
  }
}

@media (max-width: 500px) {
  .CardText p {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .MainDiv {
    grid-template-columns: 39px 80% !important;
  }

  .Circle {
    top: 45px;
  }
}
